<template>
	<footer class="footer">
		<div class="footer-width">
			<section class="footer-padding">
				<div class="footer-logo">
					<img src="../assets/img/logo.png" alt="" />
					<p :class="{ bannerTitleID: lang === 'en-ID' }">{{ $t('cashy.bannerTitle') }}</p>
				</div>
				<div class="footer-content">
					<div class="fc-left">
						<div class="products">
							<p>{{ $t('cashy.products') }}</p>
							<div class="payinout">
								<span>{{ $t('cashy.payin') }}</span>
								<!-- <span :class="{'payin':lang==='en-ID'}">{{$t('cashy.payin')}}</span> -->
								<span>{{ $t('cashy.payout') }}</span>
							</div>
						</div>
						<div class="office">
							<p>{{ $t('cashy.office') }}</p>
							<p>Graha Handaya Unit R S T JI.Raya Perjuangan NO.12 RT.1/RW.7,Kb.Jeruk West Jakarta 11530</p>
						</div>
					</div>
					<div class="contact">
						<p>{{ $t('cashy.contact') }}</p>
						<div class="email">
							<p class="service-email">Email：</p>
							<div class="c-email">
								<span>market@cashypro.com</span>
								<span>service@cashypro.com</span>
							</div>
						</div>
						<div class="contact-method">
							<div class="whatapp">
								<p>Phone/WhatsApp ：</p>
								<p>+62 858-1780-9785</p>
								<img src="../assets/img/phone_qrcode@2x.png" alt="" />
							</div>
							<div class="whatapp">
								<p>TELEGRAM ：</p>
								<p class="TELEGRAM"><a href="https://t.me/cashypay" target="_blank">@Cashypay</a></p>
								<img src="../assets/img/telegram_code@2x.png" alt="" />
							</div>
							<div class="whatapp">
								<p>TELEGRAM ：</p>
								<p class="TELEGRAM"><a href="https://t.me/cashypayaf" target="_blank">@Cashypayaf</a></p>
								<img src="../assets/img/telegram_codecashypayaf@2x.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</footer>
</template>

<script setup>
import { ref } from 'vue'
let lang = ref(sessionStorage.getItem('lang'))
// const openWA=(mobile)=> {
//   if (mobile.indexOf('08') == 0) {
//     mobile = mobile.replace('08', '628')
//   } else if (mobile.indexOf('8') == 0) {
//     mobile = mobile.replace('8', '628')
//   }
//   window.location.href = "whatsapp://send?phone=" + mobile;
// }
</script>

<style lang="scss" scoped>
.payin {
	margin-bottom: 15px;
}
.bannerTitleID {
	width: 300px;
}
.footer {
	font-family: SF-Pro-Text-Regular;
	background: #f05600;
	width: 100%;
	color: #ffffff;
	font-size: 18px;
	.footer-width {
		width: 1200px;
		margin: 0 auto;
		.footer-padding {
			display: flex;
			.footer-logo {
				padding-top: 30px;
				margin-left: 49px;
				> img {
					width: 300px;
					height: 67px;
				}
				> P {
					padding: 10px 0 0 0;
				}
			}
			.footer-content {
				width: fit-content;
				display: flex;
				justify-content: flex-end;
				padding: 42px 0;
				.fc-left {
					width: 20%;
					margin-right: 50px;
					.products {
						> p:first-child {
							margin-bottom: 20px;
							font-weight: bold;
						}
					}
					.office {
						margin-top: 26px;
						> p:last-child {
							line-height: 22px;
						}
						> p:first-child {
							margin-bottom: 20px;
							font-weight: bold;
						}
					}
					.payinout {
						display: flex;
						flex-direction: column;
					}
				}

				.contact {
					width: 65%;
					font-size: 18px;
					> p:first-child {
						margin-bottom: 20px;
						font-weight: bold;
					}
					.email {
						.c-email {
							display: flex;
							align-items: center;
							> span:first-child {
								margin-right: 50px;
							}
						}
					}
					.contact-method {
						display: flex;
						align-items: center;
						justify-content: space-between;
						.whatapp {
							display: flex;
							flex-direction: column;
							margin-top: 20px;
							line-height: 22px;
							.TELEGRAM {
								> a {
									color: #121cdd;
									text-decoration: underline;
								}
							}
							> img {
								margin-top: 13px;
								width: 160px;
								height: 160px;
							}
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 1440px) {
	.footer {
		color: #ffffff;
		font-size: 14px;
		.footer-width {
			width: 900px;
			margin: 0 auto;
			.footer-padding {
				.footer-logo {
					padding-top: 23px;
					margin-left: 37px;
					> img {
						width: 225px;
						height: 50px;
					}
					> P {
						padding: 10px 0 0 0;
					}
				}
				.footer-content {
					padding: 31px 0;
					.fc-left {
						width: 21%;
						margin-right: 50px;
						.products {
							> p:first-child {
								margin-bottom: 10px;
								font-weight: bold;
							}
						}
						.office {
							margin-top: 26px;
							> p:last-child {
								line-height: 17px;
							}
							> p:first-child {
								margin-bottom: 10px;
								font-weight: bold;
							}
						}
					}

					.contact {
						width: 65%;
						font-size: 14px;
						> p:first-child {
							margin-bottom: 20px;
							font-weight: bold;
						}
						.email {
							.c-email {
								display: flex;
								align-items: center;
								> span:first-child {
									margin-right: 50px;
								}
							}
						}
						.contact-method {
							display: flex;
							align-items: center;
							justify-content: space-between;
							.whatapp {
								display: flex;
								flex-direction: column;
								margin-top: 20px;
								line-height: 22px;
								> span:first-child {
									margin-bottom: 15px;
								}
								> img {
									margin-top: 13px;
									width: 113px;
									height: 113px;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 1130px) {
	.footer {
		color: #ffffff;
		font-size: 12px;
		.footer-width {
			// width: 700px;
			margin: 0 auto;
			.footer-padding {
				.footer-logo {
					padding-top: 23px;
					margin-left: 37px;
					> img {
						width: 225px;
						height: 50px;
					}
					> P {
						padding: 10px 0 0 0;
					}
				}
				.footer-content {
					padding: 31px 0;
					.fc-left {
						width: 23%;
						margin-right: 50px;
						.products {
							> p:first-child {
								margin-bottom: 10px;
								font-weight: bold;
							}
						}
						.office {
							margin-top: 26px;
							> p:last-child {
								line-height: 17px;
							}
							> p:first-child {
								margin-bottom: 10px;
								font-weight: bold;
							}
						}
					}

					.contact {
						width: 60%;
						font-size: 12px;
						> p:first-child {
							margin-bottom: 20px;
							font-weight: bold;
						}
						.email {
							.c-email {
								display: flex;
								align-items: center;
								> span:first-child {
									margin-right: 50px;
								}
							}
						}
						.contact-method {
							display: flex;
							align-items: center;
							justify-content: space-between;
							.whatapp {
								display: flex;
								flex-direction: column;
								margin-top: 20px;
								line-height: 22px;
								> span:first-child {
									margin-bottom: 15px;
								}
								> img {
									margin-top: 13px;
									width: 88px;
									height: 88px;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 750px) {
	.footer {
		font-size: 12px;
		.footer-width {
			width: 100%;
			.footer-padding {
				padding: 10px 0 0 16px;
				display: flex;
				flex-direction: column;
				.footer-logo {
					padding-top: 0;
					margin-left: 0;
					> img {
						width: 93px;
						height: 20px;
						margin-bottom: 4px;
					}
					> P {
						padding: 0;
					}
				}
				.footer-content {
					display: flex;
					flex-direction: column;
					padding-top: 10px;
					.fc-left {
						width: 61%;
						margin-right: 0;
						.products {
							> p:first-child {
								font-weight: bold;
								margin: 0 0 6px 0;
							}
						}
						.office {
							margin-top: 10px;
							> p:last-child {
								line-height: 16px;
							}
							> p:first-child {
								margin-bottom: 6px;
								font-weight: bold;
							}
						}
					}
					.contact {
						width: 95%;
						font-size: 12px;
						.email {
							display: flex;
							align-items: center;
							margin-bottom: 6px;
							.c-email {
								> span:first-child {
									margin-right: 20px;
								}
							}
						}
						> p:first-child {
							margin-bottom: 6px;
							margin-top: 10px;
							font-weight: bold;
						}

						.contact-method {
							.whatapp {
								margin-top: 0px;
								line-height: 16px;
								> span:first-child {
									margin-bottom: 15px;
								}
								> img {
									margin-top: 5px;
									width: 105px;
									height: 105px;
								}
							}
							> div:nth-of-type(2) {
								margin-left: -5px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
