<template>
	<main class="main-content">
		<section class="main-banner">
			<div class="banner-content">
				<div class="banner-margin">
					<p class="banner-title" :class="{ isPhoneTitle: lang === 'en-ID' && isPC === false, titleID: lang === 'en-ID' && isPC === true }">{{ $t('cashy.bannerTitle') }}</p>
					<p class="banner-text" :class="{ isPhoneText: lang === 'en-ID' && isPC === false, textID: lang === 'en-ID' && isPC === true }">{{ $t('cashy.bannerText') }}</p>
					<div class="contactBtn">
						<span @click="contactClick">{{ $t('cashy.contactBtn') }}</span>
					</div>
				</div>
			</div>
		</section>
		<section class="main-coagency">
			<div class="coagency-width">
				<div class="coagency-content">
					<div>
						<h2>Virtual Account</h2>
						<div>
							<img src="../assets/img/img_bca@2x.png" alt="" />
							<img src="../assets/img/img_bri@2x.png" alt="" />
							<img src="../assets/img/img_permata@2x.png" alt="" />
							<img src="../assets/img/img_mandiri@2x.png" alt="" />
							<img src="../assets/img/img_atm@2x.png" alt="" />
						</div>
						<div>
							<img src="../assets/img/img_alfa@2x.png" alt="" />
							<img src="../assets/img/img_bni@2x.png" alt="" />
							<img src="../assets/img/img_cimb@2x.png" alt="" />
							<img src="../assets/img/img_indomaret@2x.png" alt="" />
						</div>
					</div>
					<div>
						<h2>E-wallet / QRIS</h2>
						<div>
							<img src="../assets/img/img_gopay@2x.png" alt="" />
							<img src="../assets/img/img_dana@2x.png" alt="" />
							<img src="../assets/img/img_ovo@2x.png" alt="" />
							<img src="../assets/img/img_linkaja@2x.png" alt="" />
						</div>
						<div>
							<img src="../assets/img/img_qris@2x.png" alt="" />
							<img src="../assets/img/img_shopeepay@2x.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="main-state">
			<div class="state-width">
				<div class="state-margin">
					<div class="top-state">
						<p>{{ $t('cashy.answer') }}</p>
						<p>{{ $t('cashy.cashyState') }}</p>
					</div>
					<div class="top-card">
						<div>
							<div class="card">
								<img src="../assets/img/ico_zhenghe.png" alt="" />
								<p>{{ $t('cashy.integration') }}</p>
							</div>
							<div class="card-text">
								<p>{{ $t('cashy.integrationText') }}</p>
							</div>
						</div>
						<div>
							<div class="card">
								<img src="../assets/img/ico_speed.png" alt="" />
								<p>{{ $t('cashy.speed') }}</p>
							</div>
							<div class="card-text">
								<p>{{ $t('cashy.speedText') }}</p>
							</div>
						</div>
						<div>
							<div class="card">
								<img src="../assets/img/ico_cs.png" alt="" />
								<p>{{ $t('cashy.customer') }}</p>
							</div>
							<div class="card-text">
								<p>{{ $t('cashy.customerText') }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'
const emits = defineEmits(['contactClick'])
let lang = ref()
let isPC = ref(false)
lang.value = sessionStorage.getItem('lang')
const contactClick = () => {
	emits('contactClick')
}
onMounted(() => {
	if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
		isPC.value = false
		sessionStorage.setItem('isPC', false)
		//手机端
	} else {
		isPC.value = true
		sessionStorage.setItem('isPC', false)
	}
})
</script>
>

<style lang="scss" scoped>
.titleID {
	font-size: 40px !important;
}
.isPhoneTitle {
	font-size: 12px !important;
}
.isPhoneText {
	transform: scale(0.8) !important;
	transform-origin: 0 !important;
	width: 70% !important;
}
.main-content {
	width: 100%;
	font-family: SF-Pro-Text-Regular;

	.main-banner {
		background: #fff;
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		.banner-content {
			max-width: 1440px;
			background: url('../assets/img/img_banner.png') no-repeat center/100%;
			.banner-margin {
				margin-left: 60px;
				.banner-title {
					color: #f05600;
					font-size: 52px;
					padding-top: 90px;
					font-weight: bold;
				}
				.banner-text {
					font-size: 24px;
					color: #f05600;
					line-height: 32px;
					width: 50%;
					font-weight: 400;
					margin: 70px 0;
				}
				.contactBtn {
					cursor: pointer;
					padding-bottom: 100px;
					width: 180px;
					text-align: center;
					> span {
						display: block;
						padding: 14px 0;
						background: #f05600;
						box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
						border-radius: 17px 17px 17px 17px;
						font-size: 24px;
						font-weight: bold;
						color: #fff;
					}
				}
			}
		}
	}
	.main-coagency {
		width: 100%;
		.coagency-width {
			max-width: 1440px;
			margin: 0 auto;
			.coagency-content {
				padding-bottom: 50px;
				> div {
					text-align: center;
					display: flex;
					justify-content: center;
					flex-direction: column;
					> h2 {
						margin: 50px 0 40px 0;
						font-size: 40px;
						color: #f05600;
					}
					> div {
						> img {
							height: 120px;
							width: 200px;
							margin: 0 20px 20px 0;
						}
					}
				}
			}
		}
	}
	.main-state {
		width: 100%;
		background: #fff;
		.state-width {
			max-width: 1440px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
		}
		.state-margin {
			padding: 0 0 123px 0;
			width: 75%;
			box-sizing: border-box;
			.top-state {
				p:first-child {
					font-size: 36px;
					font-weight: bold;
					color: #f05600;
					margin-bottom: 30px;
				}
				p:last-child {
					font-size: 24px;
					color: #5c5b5a;
					line-height: 32px;
					margin-bottom: 100px;
				}
			}
			.top-card {
				padding: 0;
				display: flex;
				justify-content: space-between;
				> div {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 30%;
					.card {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-bottom: 0;
						> img {
							width: 88px;
							height: 88px;
						}
						> p {
							font-weight: 400;
							color: #f05600;
							font-size: 24px;
							margin: 15px 0 25px 0;
						}
					}

					.card-text {
						font-size: 22px;
						color: #5c5b5a;
						line-height: 32px;
						background: rgba(255, 92, 0, 0.04);
						padding: 20px 8px 40px 8px;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1440px) {
	.titleID {
		font-size: 32px !important;
	}
	.textID {
		font-size: 22px !important;
	}
	.main-content {
		width: 100%;
		.main-banner {
			background: #fff;
			max-width: 1440px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			.banner-content {
				max-width: 1440px;
				background: url('../assets/img/img_banner.png') no-repeat center/100%;
				.banner-margin {
					margin-left: 60px;
					.banner-title {
						color: #f05600;
						font-size: 46px;
						padding-top: 40px;
						font-weight: bold;
					}
					.banner-text {
						font-size: 24px;
						color: #f05600;
						line-height: 32px;
						width: 43%;
						font-weight: 400;
						margin: 60px 0;
					}
					.contactBtn {
						padding-bottom: 100px;
						width: 180px;
						text-align: center;
						cu > span {
							display: block;
							padding: 22px 0;
							background: #f05600;
							box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
							border-radius: 17px 17px 17px 17px;
							font-size: 24px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
		.main-coagency {
			width: 100%;
			.coagency-width {
				width: 900px;
				margin: 0 auto;
				.coagency-content {
					padding-bottom: 40px;
					> div {
						> h2 {
							margin: 40px 0 30px 0;
							font-size: 30px;
							color: #f05600;
						}
						> div {
							> img {
								height: 90px;
								width: 150px;
								margin: 0 15px 15px 0;
							}
						}
					}
				}
			}
		}
		.main-state {
			width: 100%;
			background: #fff;
			.state-width {
				max-width: 1120px;
				margin: 0 auto;
				display: flex;
				justify-content: center;
			}
			.state-margin {
				padding: 0 0 100px 0;
				width: 76%;
				box-sizing: border-box;
				.top-state {
					p:first-child {
						font-size: 28px;
						font-weight: bold;
						color: #f05600;
						margin-bottom: 20px;
					}
					p:last-child {
						font-size: 19px;
						color: #5c5b5a;
						line-height: 25px;
						margin-bottom: 70px;
					}
				}
				.top-card {
					padding: 0;
					display: flex;
					justify-content: space-between;
					> div {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 30%;
						.card {
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-bottom: 0;
							> img {
								width: 68px;
								height: 68px;
							}
							> p {
								font-weight: 400;
								color: #f05600;
								font-size: 19px;
								margin: 10px 0 20px 0;
							}
						}

						.card-text {
							font-size: 17px;
							color: #5c5b5a;
							line-height: 25px;
							background: rgba(255, 92, 0, 0.04);
							padding: 16px 6px 30px 8px;
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 1280px) {
	.textID {
		font-size: 20px !important;
	}
	.main-content {
		width: 100%;
		.main-banner {
			background: #fff;
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			.banner-content {
				max-width: 1280px;
				background: url('../assets/img/img_banner.png') no-repeat center/100%;
				.banner-margin {
					margin-left: 60px;
					.banner-title {
						color: #f05600;
						font-size: 40px;
						padding-top: 70px;
						font-weight: bold;
					}
					.banner-text {
						font-size: 22px;
						color: #f05600;
						line-height: 25px;
						// width: 43%;
						font-weight: 400;
						margin: 60px 0;
					}
					.contactBtn {
						padding-bottom: 110px;
						width: 180px;
						text-align: center;
						> span {
							display: block;
							padding: 11px 0;
							background: #f05600;
							box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
							border-radius: 17px;
							font-size: 22px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
		.main-coagency {
			width: 100%;
			.coagency-width {
				width: 800px;
				margin: 0 auto;
				.coagency-content {
					padding-bottom: 40px;
					> div {
						> h2 {
							margin: 40px 0 30px 0;
							font-size: 27px;
							color: #f05600;
						}
						> div {
							> img {
								height: 80px;
								width: 133px;
								margin: 0 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 1227px) {
	.main-content {
		width: 100%;
		.main-banner {
			background: #fff;
			max-width: 1440px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			.banner-content {
				max-width: 1440px;
				background: url('../assets/img/img_banner.png') no-repeat center/100%;
				.banner-margin {
					margin-left: 60px;
					.banner-title {
						color: #f05600;
						font-size: 40px;
						padding-top: 50px;
						font-weight: bold;
					}
					.banner-text {
						font-size: 22px;
						color: #f05600;
						line-height: 25px;
						// width: 43%;
						font-weight: 400;
						margin: 60px 0;
					}
					.contactBtn {
						padding-bottom: 110px;
						width: 180px;
						text-align: center;
						> span {
							display: block;
							padding: 11px 0;
							background: #f05600;
							box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
							border-radius: 17px 17px 17px 17px;
							font-size: 24px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
		.main-coagency {
			width: 100%;
			.coagency-width {
				width: 800px;
				margin: 0 auto;
				.coagency-content {
					padding-bottom: 40px;
					> div {
						> h2 {
							margin: 40px 0 30px 0;
							font-size: 27px;
							color: #f05600;
						}
						> div {
							> img {
								height: 80px;
								width: 133px;
								margin: 0 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 1130px) {
	.main-content {
		width: 100%;
		.main-banner {
			background: #fff;
			max-width: 1120px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			.banner-content {
				max-width: 1120px;
				background: url('../assets/img/img_banner.png') no-repeat center/100%;
				.banner-margin {
					margin-left: 60px;
					.banner-title {
						color: #f05600;
						font-size: 40px;
						padding-top: 65px;
						font-weight: bold;
					}
					.banner-text {
						font-size: 19px;
						color: #f05600;
						line-height: 25px;
						// width: 43%;
						font-weight: 400;
						margin: 32px 0;
					}
					.contactBtn {
						padding-bottom: 100px;
						width: 140px;
						text-align: center;
						> span {
							display: block;
							padding: 11px 0;
							background: #f05600;
							box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
							border-radius: 17px 17px 17px 17px;
							font-size: 19px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
		.main-coagency {
			width: 100%;
			.coagency-width {
				width: 800px;
				margin: 0 auto;
				.coagency-content {
					padding-bottom: 40px;
					> div {
						> h2 {
							margin: 40px 0 30px 0;
							font-size: 27px;
							color: #f05600;
						}
						> div {
							> img {
								height: 80px;
								width: 133px;
								margin: 0 10px 10px 0;
							}
						}
					}
				}
			}
		}
		.main-state {
			width: 100%;
			background: #fff;
			.state-width {
				max-width: 1120px;
				margin: 0 auto;
				display: flex;
				justify-content: center;
			}
			.state-margin {
				padding: 0 0 100px 0;
				width: 76%;
				box-sizing: border-box;
				.top-state {
					p:first-child {
						font-size: 28px;
						font-weight: bold;
						color: #f05600;
						margin-bottom: 20px;
					}
					p:last-child {
						font-size: 19px;
						color: #5c5b5a;
						line-height: 25px;
						margin-bottom: 70px;
					}
				}
				.top-card {
					padding: 0;
					display: flex;
					justify-content: space-between;
					> div {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 30%;
						.card {
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-bottom: 0;
							> img {
								width: 68px;
								height: 68px;
							}
							> p {
								font-weight: 400;
								color: #f05600;
								font-size: 19px;
								margin: 10px 0 20px 0;
							}
						}

						.card-text {
							font-size: 17px;
							color: #5c5b5a;
							line-height: 25px;
							background: rgba(255, 92, 0, 0.04);
							padding: 16px 6px 30px 8px;
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 1000px) {
	.main-content {
		width: 100%;
		.main-banner {
			max-width: 1000px;
			.banner-content {
				max-width: 1000px;
				background: url('../assets/img/img_banner.png') no-repeat center/100%;
				.banner-margin {
					margin-left: 30px;
					.banner-title {
						color: #f05600;
						font-size: 28px;
						padding-top: 60px;
						font-weight: bold;
					}
					.banner-text {
						font-size: 18px;
						color: #f05600;
						line-height: 25px;
						width: 60%;
						font-weight: 400;
						margin: 30px 0;
						width: 45%;
					}
					.contactBtn {
						padding-bottom: 70px;
						width: 120px;
						text-align: center;
						> span {
							display: block;
							padding: 11px 0;
							background: #f05600;
							box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
							border-radius: 17px 17px 17px 17px;
							font-size: 16px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
		.main-coagency {
			width: 100%;
			.coagency-width {
				width: 800px;
				margin: 0 auto;
				.coagency-content {
					padding-bottom: 40px;
					> div {
						> h2 {
							margin: 40px 0 30px 0;
							font-size: 27px;
							color: #f05600;
						}
						> div {
							> img {
								height: 80px;
								width: 133px;
								margin: 0 10px 10px 0;
							}
						}
					}
				}
			}
		}
	}
}
@media all and (max-width: 750px) {
	.isPhoneText {
		transform: scale(0.8) !important;
		transform-origin: 0 !important;
		// width: 30%!important;
	}
	.main-content {
		width: 100%;
		.main-banner {
			background: #fff;
			width: 100%;
			padding: 0 16px;
			margin-top: 10px;
			.banner-content {
				background: url('../assets/img/Frame500.png') no-repeat center/100% 100%;
				.banner-margin {
					width: 100%;
					margin-left: 0;
					padding: 0 16px;
					font-family: Source Han Sans CN-Bold, Source Han Sans CN;
					display: flex;
					flex-direction: column;
					.banner-title {
						color: #f05600;
						font-size: 16px;
						padding-top: 19px;
						font-weight: 700;
					}
					.banner-text {
						font-size: 12px;
						color: #f05600;
						line-height: 14px;
						width: 62%;
						font-weight: 400;
						margin: 8px 0 20px 0;
					}
					.contactBtn {
						padding-bottom: 25px;
						text-align: center;
						width: 25%;
						> span {
							width: 100%;
							display: block;
							box-sizing: border-box;
							background: #f05600;
							box-shadow: 5px 8px 11px 0px rgba(255, 91, 0, 0.4);
							border-radius: 13px;
							font-size: 12px;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
		}
		.main-coagency {
			width: 100%;

			.coagency-width {
				width: 100%;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.coagency-content {
					padding-bottom: 20px;
					> div {
						> h2 {
							margin: 20px 0 15px 0;
							font-size: 16px;
							color: #f05600;
						}
						> div {
							> img {
								height: 38px;
								width: 63px;
								margin: 0 5px 5px 0;
							}
						}
					}
				}
			}
		}
		.main-state {
			width: 100%;
			background: #fff;
			.state-width {
				width: 100%;
				margin: 0 auto;
			}
			.state-margin {
				padding: 0 9px;
				width: 100%;
				box-sizing: border-box;
				font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				.top-state {
					display: flex;
					flex-direction: column;
					> p:first-child {
						font-size: 12px;
						font-weight: bold;
						color: #f05600;
						margin-bottom: 8px;
					}
					> p:last-child {
						font-size: 10px;
						color: #b0b0b0;
						line-height: 14px;
						margin-bottom: 16px;
						// padding: 0 4px;
					}
				}
				.top-card {
					padding: 0 7px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					> div {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						width: 100%;
						.card {
							display: flex;
							align-items: center;
							flex-direction: row;
							margin-bottom: 8px;
							// justify-content: center;
							> img {
								width: 24px;
								height: 24px;
							}
							> p {
								font-weight: 400;
								color: #f05600;
								font-size: 12px;
								margin: 0 0 0 12px;
							}
						}
						.card-text {
							font-size: 10px;
							color: #5c5b5a;
							line-height: 14px;
							background: rgba(255, 92, 0, 0.04);
							padding: 7px 9px;
							margin-bottom: 16px;
						}
					}
				}
			}
		}
	}
}
</style>
